.wrapper {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 16px);
    background-color: var(--color-back-primary);
    position: relative;
    flex-direction: column;
    /*justify-content: space-between;*/
    padding: 0 0px 20px 16px;
    border-radius: 20px;
    margin-bottom: 8px;
}

.title{
    font-size: 20px;
    color: var(--color-text-in-light);
    font-weight: 700;
    padding: 20px 0  16px;
}
.sliderWrapper {
    overflow: hidden; /* Прячем элементы, выходящие за пределы контейнера */
    position: relative;
    width: 100%; /* Контейнер занимает 100% ширины родительского элемента */
    max-width: calc(100vw - 16px);
}

.slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory; /* Автоматический скролл по элементам */
}

.slider::-webkit-scrollbar {
    display: none; /* Скрыть скроллбар для более чистого дизайна */
}
.item {
    min-width: 210px;
    min-height: 230px;
    margin: 0px 4px;
}

.item > div {
    font-size: 16px;
    font-weight: 700;
    color:#1F1D2B;
    padding: 16px;
    max-height: 230px;
    box-sizing: border-box;
    height: 230px;
    position: relative;
    z-index: 2;
}
.item > div > div {
    position: relative;
    z-index: 2;
}

.menuButtonCategory {
    background-color: #F3F2F8;
    border-radius: 12px;
    height: 112px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.link {

    text-decoration: none;
}
.link:last-of-type {
margin-right: 10px;
}

.menuButtonCategory img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;

}


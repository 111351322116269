body.white {
  --color-back-primary:#fff;
  --color-back-secondary:#F3F2F8;
  --color-line:#E3E4EA;
  --color-gray-fill:#F3F2F8;


  --color-text-in-light:#1F1D2B;
  --color-text-in-dark:#fff;
  --color-text-secondary:#8E919F;


  --color-icons-white:#fff;
  --color-icons-light:#C7C9D2;
  --color-icons-dark:#6A6E81;
  --color-icons-black:#1F1D2B;


  --color-blue-accent:#59ABFE;
  --color-success:#10C44C;
  --color-orange-accent:#F85D42;
  --gradient-100: linear-gradient(
          #F85D42,
          #FF9900
  );

  --color-black-body:#F3F2F8;

}


.wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}

.title {
    width: 100%;
    color: #1F1D2B;
    font-size: 20px;
    font-weight: 700;
}
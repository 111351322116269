.wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 0 19px;
background-color: var(--color-black-body);
    min-height: 100vh;
}

.wrapperBack{
    background-color: var(--color-back-primary);
}

.title {
    color: var(--color-text-in-light);
    font-size: 20px;
    display: block;
    font-weight: 700;
    padding: 112px 20px 40px;
    text-align: center;
    box-sizing: border-box;
}

.titleOrder {
    color: var(--color-text-in-light);
    font-size: 20px;
    display: block;
    position: fixed;
    font-weight: 700;
    padding: 19px 0px 19px;
    text-align: center;
    box-sizing: border-box;
    top: 0;
    z-index: 3;
    width: 100%;
    left: 0;

    background-color: var(--color-back-primary);
}

.mintitle {
    color: var(--color-text-in-light);
    font-size: 20px;
    display: block;
    font-weight: 700;
    border-radius: 20px 20px 0 0;
    background-color: var(--color-back-primary);
    padding: 20px 16px 0px;
    box-sizing: border-box;
}

.titleIsOrder {
    color: var(--color-text-in-light);
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: 700;
    padding: 40px 20px 30px;
    border-radius: 0 0 20px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: var(--color-back-primary);
}


.titleIsOrder span {
    width: 100%;
    display: block;
    margin-top: 24px;
}

.descriptionTitle{
    font-size: 14px;
    margin-top: 12px;
    font-weight: 600;
}

.wrapItem{
    background-color: var(--color-back-primary);
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    padding: 20px 16px 8px;
}

.wrapItemOrder{
    border-radius: 20px;
    background-color: var(--color-back-primary);
    box-sizing: border-box;
    padding: 8px 16px ;
}

.wrapItemOrderNone{
    display: none;
}

.itemsBlock {
    position: relative;
    padding: 0px 16px;
    background-color: var(--color-back-primary);
    box-sizing: border-box;
}

.itemsBlockSostav {
    position: relative;
    padding: 0px 0px;
    background-color: var(--color-back-primary);
    box-sizing: border-box;
}

.item {
    position: relative;
    padding: 16px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid var(--color-line);
}

.item:last-child{
    border-bottom: none;
    /*border-radius: 0 0 20px 20px;*/
}

.itemsBlock .item:last-child{
    border-bottom: 1px solid var(--color-line);
}

.itemsBlockSostav .item:last-child{
    border-bottom: 1px solid var(--color-line);
}

.blockLeft {
    display: flex;
}

.wrapItemOrder .item:last-child{
    border-bottom: 0;
}

.itemImg {
    width: 84px;
    height: 84px;
    min-width: 84px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 16px;
}

.itemImg img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.text {
    min-height: 46px;
    margin-bottom: 12px;
    font-size: 11px;
    color: var(--color-text-in-light);
    font-weight: bold;
    padding-bottom: 5px;
    box-sizing: border-box;
}

.textOrder {
    font-size: 13px;
    font-weight: 600;
    max-width: 230px;
    color: var(--color-text-in-light);

    box-sizing: border-box;
}

.textOrder span{
    color:var(--color-text-secondary);
    }

.textPreorder{
    margin-top: 4px;
    font-size: 11px;
    margin-left: 20px;
    color: #9d9d9d;
    font-weight: bold;
    padding-bottom: 5px;
    box-sizing: border-box;
}


.textNoneMargine{
    min-height: 0;
    margin-bottom: 0;
}

.textGray {
    font-size: 11px;
    margin-left: 20px;
    color: #9d9d9d;
    font-weight: bold;
    padding-bottom: 5px;
    box-sizing: border-box;
}


.buttonGray {
    display: flex;
    justify-content: space-between;
    min-width: 97px;
    background-color: var(--color-gray-fill);
    overflow: hidden;
    margin-top: 12px;
    border-radius: 55px;
    align-items: center;
    color: var(--color-text-in-light);
    font-weight: bold;
    font-size: 14px;
    max-width: 97px;
    padding: 6px 9px;
    box-sizing: border-box;
}

.menuSale {
    background-color: #F3F2F8;
    padding: 5px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
}

.wrapperBtn {
    padding: 20px;
}

.homepageSale {
    height: 100px;
}

/*.minus:after {*/
/*    content: "-";*/
/*    margin-left: -2px;*/
/*    margin-top: -2px;*/
/*    box-shadow: 0 0 0 0;*/
/*    border: 0;*/
/*    color: #F85D42;*/
/*    width: 29px;*/
/*    font-size: 16px;*/
/*    border-radius: 0;*/
/*}*/

.minus {
    margin-left: -2px;
    margin-top: 0px;
    box-shadow: 0 0 0 0;
    border: 0;
    width: 29px;
    height: 100%;
    border-radius: 0;
    background-color: var(--color-gray-fill);
}
.minus svg path{
    stroke: var(--color-orange-accent);

}
.minus svg {
   width: 10px;
    height: 10px;

}
.plus svg path{
    stroke: var(--color-orange-accent);
}
.plus svg {
    width: 10px;
    height: 10px;
}
/*.plus:after {*/
/*    content: "+";*/
/*    color: #F85D42;*/
/*    font-size: 16px;*/

/*    text-align: center;*/
/*    margin-right: -2px;*/
/*    width: 29px;*/
/*}*/

.plus {
    border: 0;
    width: 29px;
    height: 100%;
    margin-right: -2px;
    background-color: var(--color-gray-fill);
}

.blockRight {
    display: flex;
    flex-direction: column;
    align-items: end;
    box-sizing: border-box;
    padding-left: 5px;
}

.blockRightOrder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sumOrder{
    display: flex;
    justify-content: space-between;
    color: var(--color-text-in-light);
    font-size: 14px;
    font-weight: 700;
}

.iconRight {
    margin-bottom: 40px;
}

.summ {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-text-in-light);
    text-wrap: nowrap;
}

@media (min-width: 0px) and (max-width: 350px) {
    .summ{
        font-size: 13px;
    }
}

.discounts {
    color: var(--color-orange-accent);
    margin-right: 10px;
}

.pricesDisc {
    color: var(--color-text-secondary);
    position: relative;
}

.pricesDisc:before {
    content: "";
    border-bottom: 2px solid var(--color-text-secondary);
    position: absolute;
    width: 100%;
    top: 10px;
    height: 0;
    transform: rotate(196deg);
}

.sostav {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: var(--color-back-primary);
    padding: 12px 16px 0;
    border-radius: 20px;
    box-sizing: border-box;
}

.titleSostav {
    border-radius: 20px;
    background-color: var(--color-back-primary);
    margin-bottom: 8px;
    width: 100%;
    padding: 0px 16px;
box-sizing: border-box;
    /*border-radius: 0 0 20px 20px;*/
}
.titleSostavs {
    display: flex;
    padding: 16px 0px;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
box-sizing: border-box;
    border-bottom: 1px solid var(--color-line);
    /*border-radius: 0 0 20px 20px;*/
}
.titleSostavItem {
    display: flex;
    padding: 8px 0px;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
box-sizing: border-box;

    /*border-radius: 0 0 20px 20px;*/
}
.titleSostavItemSkidka {
    display: flex;
    padding: 8px 0 16px;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    box-sizing: border-box;

    /*border-radius: 0 0 20px 20px;*/
}
.titleSostavItems {
    display: flex;
    padding:16px 0px;
    border-top: 1px solid var(--color-line);
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
box-sizing: border-box;

    /*border-radius: 0 0 20px 20px;*/
}

.titleSostav:last-of-type {
    margin-top: 10px;
    padding-top: 20px;
border-top: 1px solid #E8E9F0;
}

.titleTextSostav {
    color: var(--color-text-in-light);
    font-weight: 700;
    font-size: 20px;
    padding-top: 20px;
}

.titleSostav2 {
box-sizing: border-box;
    background-color: var(--color-back-primary);
    width: 100%;
    padding: 0px 16px;
    /*border-radius: 0 0 20px 20px;*/
}

.titleSostavs2 {
    display: flex;
    border-bottom: 1px solid var(--color-line);
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 0px;
    /*border-radius: 0 0 20px 20px;*/
}

.titleSostav2:last-of-type {
    padding-top: 20px;
    margin-bottom: 8px;
border-top: 1px solid #E8E9F0;
}

.titleSostav3 {
    display: flex;
    background-color: var(--color-back-primary);
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 16px 20px;
    border-radius: 0 0 20px 20px;
}

.titleTextSostav2 {
    color: var(--color-text-in-light);
    font-weight: 600;
    font-size: 14px;
}

.position {
    font-size: 11px;
    color: #8E919F;
    font-weight: 500;
}

.titleTextSostavPos {
    color: var(--color-text-in-light);
    font-size: 14px;
}

.positionSumDisc {
    color: var(--color-orange-accent);
    font-size: 13px;
    font-weight: 600;
}


.positionSum {
    color: var(--color-text-in-light);
    font-size: 13px;
    font-weight: 600;
}

.titleTextSostavTog {
    color: var(--color-text-in-light);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
}

.titleTextSostavTog div{
    margin-bottom: 4px;
}

.titleTextSostavTog span{
    color: var(--color-text-secondary);
    font-size: 14px;
    font-weight: 600;
}

.titleTextSostavTog2 {
    color: var(--color-text-in-light);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
}

.titleTextSostavTog2 div{
    margin-bottom: 4px;
}

.titleTextSostavTog2 span{
    color: var(--color-text-secondary);
    font-size: 14px;
    font-weight: 600;
}

.positionItog {
    color: var(--color-text-in-light);
    font-size: 20px;
    font-weight: 700;
}

.descriptionText{
    color: #8E919F;
    font-size: 12px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 20px;
}

.wrapperRec{
    padding-bottom: 70px;

    flex: 1 0 0;
    border-radius: 20px 20px 0 0 ;
    background-color: var(--color-back-primary);
}

.ButtonItog{
    background-color: var(--color-gray-fill);
    width: 100%;
    padding: 14px;
    max-width: 280px;
    box-sizing: border-box;
    font-size: 14px;
    color: var(--color-orange-accent);
    font-weight: 600;
    border-radius: 55px;
    line-height: 18px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.ButtonItogHookah{
    background-color: var(--color-gray-fill);
    width: 100%;
    padding: 14px;
    max-width: 280px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-orange-accent);
    font-weight: 600;
    border-radius: 55px;
    cursor: pointer;
    /*margin-top: 10px;*/
    margin-bottom: 10px;
    text-align: center;
}

.titleIsOrder .ButtonItog{
    margin: 10px auto;
}
.titleIsOrder .ButtonItogHookah{
    margin: 10px auto;
}
.titleIsOrder svg{
    margin: 0 auto;
}

.ButtonOrder{
    background-color: var(--color-orange-accent);
    width: 100%;
    padding: 14px 18px;
    max-width: 280px;
    box-sizing: border-box;
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 55px;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
}

.btnActive{
max-width: 100%;
}

.btnFixed{
    position: fixed;
    bottom: 70px;
    box-sizing: border-box;
    display: flex;
    left: 0;
    padding: 0px 16px;
    width: 100%;
    z-index: 3;
    background-color: var(--color-back-primary);
}

.textBtnFixed{
    width: 110px;
    box-sizing: border-box;
    padding-right: 10px;
    color: var(--color-text-in-light);
    font-weight: 700;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fistText{
color: var(--color-text-secondary);
    font-size: 12px;
    font-weight: 600;
}


.href{
    text-decoration: none;
}
.ButtonRec{
    background-color: #70B2F5;
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    border-radius: 55px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.disabled{
    pointer-events: none;
    background: #797979;
}

.wrapperMenu{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconMenu{
    margin-bottom: 20px;
}

.textMenu{
    color: var(--color-text-in-light);
    font-size: 14px;
    text-align: center;
    max-width: 255px;
    margin-bottom: 20px;
}

.hrefDecor{
    text-decoration: none;
}

.buttMenu{
    color: var(--color-text-in-dark);
    padding: 14px 28px;
    box-sizing: border-box;
    width: 100%;
    max-width: 280px;
    min-width: 280px;
    text-align: center;
    border-radius: 55px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    background-color:  var(--color-orange-accent);
}


.titleIsOrder .ButtonItogHookah{
    margin-top: 0;
}
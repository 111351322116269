.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    padding: 0 16px;
    background-color: var(--color-back-primary);
    border-radius: 20px 20px 0 0;
    margin-bottom: 10px;
    padding-bottom: 200px;
    box-sizing: border-box;
}

.title {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text-in-light);
    padding-top: 24px;
    padding-bottom: 16px;
    width: 100%;
}

.item {
    width: 100%;
    margin: 10px 0;
}

.itemOrdr{
    /*width: calc(50% - 8px);*/
    /*margin: 10px 3.5px;*/
    width: 50%;
}

.item .name {
    font-size: 14px;
    color: #1F1D2B;
}

.item .price {
    font-size: 15px;
    font-weight: 800;
    color: #F85D42;
}

.menuButtonCategory {
    border-radius: 12px;
    width: 100%;
    height: 177px;
    position: relative;
    overflow: hidden;
    background-color: #F3F2F8;
}

.menuButtonCategory img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--color-back-primary);
    padding: 15px 25px;
    box-sizing: border-box;
    width: calc(100%) ;
    z-index: 5;
    overflow: hidden;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.wrapper nav a{
    text-decoration: none;
}


.item{
    text-align: center;

}

.pulser {
    width: 24px;
    height: 24px;
    background: var(--color-success);
    border-radius: 50%;
    position: relative;
}

.pulser::after {
    animation: pulse 1000ms cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    50% {
        transform: scale(1.4);
        opacity: 0.4;
    }
}

.pulser::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--color-success);
    border-radius: 50%;
    z-index: -1;
}

.iconActive {
/* Запускаем анимацию shake и устанавливаем длительность 0.5 секунды */
    animation: shake 0.5s;
    z-index: 1;
/* Когда цикл анимации закончится, запустить ее снова */
    animation-iteration-count: infinite;
    transition: all 0.3s;
}

.iconActive svg path{
    fill: var(--color-icons-dark);

}

/* Цикл анимации */
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg);
    }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.name{
    color: var(--color-icons-dark);
    font-size: 10px;
    font-weight: 500;
    transition: color 0.3s;
    position: relative;
}

.cou{
    background-color:var(--color-success);;
    color: var(--color-text-in-dark);
    font-size: 10px;
    position: absolute;
    right: 20px;
    top:5px;
    min-width: 24px;
    min-height: 24px;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 45px;
    z-index: 2;
}

.active .name{
    color: var(--color-orange-accent);
}

.active .icon svg path{
    fill: var(--color-orange-accent);
}


.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    margin: 0 auto;
    height: 27px;
}


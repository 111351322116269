@keyframes slideInOut {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    10% {
        transform: translateY(0);
        opacity: 1;
    }
    90% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    padding: 12px 16px;
    z-index: 555;
    animation: slideInOut 5s ease-out forwards; /* Анимация длится 3 секунды и завершается */
}

.type {
    width: 100%;
    background-color: var(--color-back-primary);
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0px 4px 9px 3px #1F1D2B2E;
}

.type svg path {
    fill: darkred;
}

.item{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
}

.title{
    font-weight: 700;
    color: var(--color-text-in-light);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 2px;
}

.description{
    font-weight: 600;
    color: var(--color-text-in-light);
    font-size: 12px;
    line-height: 14px;

}

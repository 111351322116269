.wrapper{
    display: flex;
    flex-direction: column;
    padding: 0 0 70px;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: var(--color-back-primary);
}

.title{
    color: var(--color-text-in-light);
    font-weight: 700;
    font-size: 20px;

 padding: 12px 16px 16px;
}

.wrapper img{
    width: 100%;
    margin-bottom: 8px;
    border-radius: 20px;
}

.wrapImg{
    padding: 0 16px;
}
.item {
    width: 185px;
    padding: 10px 4px;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
}

.noFoto{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-gray-fill);
    color: var(--color-text-secondary);
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03px;
}

/*@media (max-width: 450px)  {*/
/*  .item{*/
/*      width: calc(50%);*/
/*      padding: 10px 4px;*/
/*  }*/
/*}*/

 .smallItem{
    width: 115px;
}

.item .name {
    font-size: 14px;
       color: var(--color-text-in-light);
    position: relative;
    z-index: 2;
    margin: 8px 0 4px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.item .gram {
    font-size: 12px;
    color: var(--color-text-secondary);
    position: relative;
    z-index: 2;
    margin: 0px 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.smallItem .name{
  font-size: 12px;
}
.item .gram {
    font-size: 12px;
    color: var(--color-text-secondary);
    position: relative;
    z-index: 2;
    margin: 0px 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.item .price {
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    max-width: max-content;
    color: var(--color-orange-accent);
    position: relative;
    box-sizing: border-box;
    padding: 4px 8px;
    z-index: 2;
    background-color: var(--color-gray-fill);
}


.discount{
    color: #D71D1D;
}

.priceDisc{
    color: #8E919F;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}

.discPersent{
    color: #D71D1D;
    font-size: 12px;
    font-weight: 500;
    position: relative;
}

.priceDisc:before{
    content: "";
    border-bottom: 2px solid #8E919F;
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
}


.menuButtonCategory {
    border-radius: 12px;
    width: 177px;
    height: 177px;
    position: relative;
    overflow: hidden;
    /*background-color: #F3F2F8;*/
}
.wrapIcon{
    position: absolute;
    left: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: var(--color-gray-fill);

}


.fast{
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 55px;
    background-color: var(--color-gray-fill);
}

.fast svg{
    width: 20px;
    height: 20px;
}

.fastActive{
    background-color: var(--color-success);
    box-sizing: border-box;
    padding: 8px 7px 8px 9px;
}

.notFastActive svg path{
    stroke: var(--color-orange-accent);
}

@media (max-width: 450px)  {
    .menuButtonCategory{
        width: 100%;
        /*margin: 10px 4px;*/
    }
}


 .smallItem .menuButtonCategory{
    width: 115px;
    height: 115px;
}


.menuButtonCategory img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.sliderWrapper {
  overflow: hidden; /* Прячем элементы, выходящие за пределы контейнера */
  position: relative;
  width: 100%; /* Контейнер занимает 100% ширины родительского элемента */
  max-width: calc(100vw - 16px);
}

.slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* Автоматический скролл по элементам */
}

.slider::-webkit-scrollbar {
  display: none; /* Скрыть скроллбар для более чистого дизайна */
}

/*.showMoreButton {*/
/*  margin-top: 10px;*/
/*  background-color: #007bff;*/
/*  color: white;*/
/*  border: none;*/
/*  padding: 10px;*/
/*  cursor: pointer;*/
/*  border-radius: 5px;*/
/*}*/

.block{
  width: 183px;
  height: 177px;
  margin-left: 4px;
  position: relative;
  margin-top: 10px;
  text-decoration: none;
}

.image{
  position: relative;
  width: 177px;
  height: 177px;
  overflow: hidden;

  border-radius: 12px;
}

.image img{
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
}

.shadow{
  position: absolute;
  z-index: 2;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  height: 100%;
}

.showMoreButton{
  position: absolute;
  width: 102px;
  height: 40px;
  background-color: var(--color-back-primary);
  color: var(--color-text-in-light);
  font-size: 12px;
  font-weight: 600;
  left: 50%;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 3;
}

.showMoreButton svg{
  margin-left: 4px;
  width: 12px;
  height: 12px;

}
.showMoreButton svg path{
  stroke: var(--color-icons-black);
}


.wrapper {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 32px);
    position: relative;
    justify-content: flex-start;
    padding: 65px 16px 70px;
    /*border-radius: 20px 20px 0 0;*/
    margin-top: -4px;
    margin-bottom: 10px;
    background-color: var(--color-back-primary);

}
.modalAll{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: var(--color-back-primary);
    top: 0;
    left: 0;
    z-index: 3;
    overflow: scroll;
    /*padding: 20px 15px;*/
    pointer-events: auto;
    box-sizing: border-box;
}
.Arrow {
    min-width: 46px;
    height: 46px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--color-gray-fill);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 16px;
}

.Arrow svg {
    width: 24px;
    height: 24px;

}

.title {
    font-size: 20px;
    font-weight: 700;
    top: 0;
    left: 0;
    background-color: var(--color-back-primary);
    z-index: 3;
    box-sizing: border-box;
    /*position: relative;*/
    padding: 19px 50px;
    min-height: 65px;
    text-align: center;
    color: var(--color-text-in-light);
    width: 100%;
    position: fixed;
}

.item {
    width: 177px;
    margin: 10px 3.5px;
}


.item name {
    font-size: 14px;
    color: #1F1D2B;

    position: relative;
    z-index: 2;
}

.item price {
    font-size: 15px;
    font-weight: 800;
    color: #F85D42;
    position: relative;
    z-index: 2;
}

.menuButtonCategory {
    border-radius: 12px;
    width: 177px;
    height: 177px;
    position: relative;
    overflow: hidden;
    background-color: #F3F2F8;
}


.menuButtonCategory img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.wrapper{
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translateX(-50%);
    width: calc(100% - 64px);
    background-color: var(--color-back-primary);
    box-sizing: border-box;
    padding: 22px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    z-index: 10;
}

.ask{
    color: var(--color-text-in-light);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.wrapButs{
    display: flex;
    align-items: center;
    justify-content: center;

}

.yes{
    background-color: var(--color-orange-accent);
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-text-in-dark);
    box-sizing: border-box;
    padding: 14px;
    min-width: 90px;
    box-shadow: none;
    border: none;
}

.no{
    background-color: var(--color-blue-accent);
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-text-in-dark);
    box-sizing: border-box;
    padding: 14px;
    min-width: 90px;
    margin-left: 10px;
    box-shadow: none;
    border: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0s 0.4s;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 5;
}


.overlay.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.4s ease;
}


.modal {
    background-color: var(--color-back-primary);
    width: 100%;
    height: 50vh;
    border-radius: 20px 20px 0 0;
    transform: translateY(100%);
    transition: transform 0.4s ease;
    display: flex;
    flex-direction: column;
}


.modal.visible {
    transform: translateY(0);
}


.modalContent {
    padding: 10px 20px 20px;
    text-align: left;
    box-sizing: border-box;
    color: var(--color-text-in-light);
}

.modalContent h2{
    margin: 0 0 16px;
}

.tableInfo{
    width: 100%;
    box-sizing: border-box;
    padding: 11px 14px;
    background-color: var(--color-back-secondary);
    border-radius: 16px;
}

.wrapper{
    display: flex;
    align-items: center;
    width: 50%;
}

.wrapper .icon{
    margin-right: 12px;
}

.item{
    display: flex;
    flex-direction: column;
}

.item .title{
    color: var(--color-text-secondary);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
}

.item .infoText{
    color: var(--color-text-in-light);
    font-weight: 600;
    font-size: 14px;
}


.back{
    position: absolute;
    background-color: var(--color-gray-fill);
    right: 12px;
    margin-top: auto;
    z-index: 2;
    border-radius: 16px;
    top: 12px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapperButtons{
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.ButtonItog{
    background-color: var(--color-back-secondary);
    width: calc(50% - 8px);
    margin: 0 4px;
    padding: 12px;
    box-sizing: border-box;
    font-size: 16px;
    color: var(--color-text-in-light);
    font-weight: 700;
    border-radius: 16px;
    line-height: 20px;
    cursor: pointer;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ButtonItogOrange{
    margin-top: 8px;
    width: 100%;
    background-color: var(--color-orange-accent);
    border-radius: 100px;
    box-sizing: border-box;
    padding: 8px 40px;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--color-text-in-dark);
    font-weight: 600;
    font-size: 14px;
}

.iconOredr{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}




.disabledOrange{
    pointer-events: none;
    color: var(--color-text-secondary);
    background-color: var(--color-line);
}

.disabled{
    pointer-events: none;
    color: var(--color-icons-light);
}

.disabled svg path{
    fill: var(--color-icons-light);
}

.textInfo{
    display: flex;
    text-align: center;
    justify-content: center;
    opacity: 0.6;
    flex-flow: row wrap;
    box-sizing: border-box;
    padding: 20px;
    margin-top: auto;
    color: var(--color-text-in-light);
    font-size: 8px;
}

.textInfo div{
    margin-right: 3px;
}

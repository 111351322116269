body.black {
  --color-back-primary:#1D2024;
  --color-back-secondary:#070707;
  --color-line:#4D4F52;
  --color-gray-fill:#2F3135;


  --color-text-in-light:#fff;
  --color-text-in-dark:#fff;
  --color-text-secondary:#828385;


  --color-icons-white:#fff;
  --color-icons-light:#363A3F;
  --color-icons-dark:#828385;
  --color-icons-black:#fff;


  --color-blue-accent:#59ABFE;
  --color-success:#10C44C;
  --color-orange-accent:#F85D42;
  --gradient-100: linear-gradient(
          #F85D42,
          #FF9900
  );

  --color-black-body:#000;
}

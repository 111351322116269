.wrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 16px;
}
.label {
  display: flex;
  align-items: center;
  text-align: left;
  user-select: none;
}
.label_title {
  color: var(--color-text-in-light);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
  margin-left: 12px;
}
.input {
  display: none;
}
.checkbox_span {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.checkbox_span::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--color-line);
  border-radius: 0.25em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* Имитация стилизованного вида при нажатии на чекбокс */
.input:checked + .checkbox_span::before {
  border-color: var(--color-orange-accent);
  background-color: var(--color-orange-accent);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
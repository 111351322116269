.fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
}


.wrapper {
    display: flex;
    flex-flow: row wrap;
    width: calc(100%);
    background-color: var(--color-back-secondary);
    position: relative;
    justify-content: space-between;
    padding: 60px 0px 70px;
    /*border-radius: 20px 20px 0 0;*/
    /*margin-top: -4px;*/

    margin-bottom: 10px;
}

.tabs{
    padding: 12px 0 8px;
    background-color: var(--color-back-primary);
}

.wrapperItem {
    width: 100%;
    border-radius: 20px;
    margin: 4px 0;
    box-sizing: border-box;
    padding: 20px 16px;
    background-color: var(--color-back-primary);
}
.wrapperItem:first-of-type {
    border-radius: 0 0 20px 20px;
    margin: 0 0 4px;
}

.wrapperTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

}

@media (max-width: 450px) {
    .wrapper {
        width: calc(100% );
        margin: 0px 0 10px;
    }
}

.title {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text-in-light);
    padding-top: 24px;
    padding-bottom: 20px;
    width: 100%;
}

.titleGroup {
    font-weight: bold;
    color: var(--color-text-in-light);
    font-size: 20px;
}

.showMoreButton{
    border: none;
    width: 86px;
    height: 28px;
    background-color: var(--color-gray-fill);
    color: var(--color-text-in-light);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    z-index: 3;
    text-decoration: none;
}

.showMoreButton svg{
    margin-left: 4px;
    width: 12px;
    height: 12px;

}
.showMoreButton svg path{
    stroke: var(--color-icons-black);
}

.wrapperGroup {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: calc(100% + 8px);
    margin: 0 -4px;
    /*overflow: hidden; !* Прячем элементы, выходящие за пределы контейнера *!*/
    /*position: relative;*/
}

.item {
    width: 177px;
    margin: 10px 3.5px;
}


.item name {
    font-size: 14px;
    color: #1F1D2B;

    position: relative;
    z-index: 2;
}

.item price {
    font-size: 15px;
    font-weight: 800;
    color: #F85D42;

    position: relative;
    z-index: 2;
}

.menuButtonCategory {
    border-radius: 12px;
    width: 177px;
    height: 177px;
    position: relative;
    overflow: hidden;
    background-color: #F3F2F8;
}


.menuButtonCategory img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.refMenu {
    position: sticky;
    top: -1px;
    z-index: 3;
    overflow: hidden;
    padding-top: 9px;

    transition: transform 0.3s;
    background-color: #fff;
    width: 100%;
    left: 0;
}

.wrapperCat {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100px;
}


.list {
    display: flex;
    position: absolute;
    top: 10px;
    left: 0;
    height: 80px;
    overflow-x: scroll;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    scroll-behavior: smooth;
}


.list::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.itemCat {
    max-width: 68px;
    min-width: 68px;
    text-align: center;
    margin-left: 16px;
}

.itemCat div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    color: #1F1D2B;
text-transform: lowercase;
}

.menuButtonCat {
    border: none;
    padding: 6px;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
    background: #F3F2F8;
    border-radius: 12px;
    position: relative;
}

.menuButtonCat img {
    width: 48px;
    height: 48px;
}
.load{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation:alls 6s linear ;
    background-color: #fff;
    animation-fill-mode:forwards;
    pointer-events: auto;
    /*background: linear-gradient(-90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);*/
    /*background-size: 400% 400%;*/
    /*animation: gradient 6s ease infinite;*/
}

.loadFirst{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation:none;
    background-color: #fff;
    animation-fill-mode:forwards;
    pointer-events: auto;
    /*background: linear-gradient(-90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);*/
    /*background-size: 400% 400%;*/
    /*animation: gradient 6s ease infinite;*/
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.close{
    animation:alls 2s linear ;
    animation-fill-mode:forwards;
    pointer-events: auto;
}
.loader{
    background-color: #FF2C3C;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /*animation:alls 6s linear ;*/
    animation-fill-mode:forwards;
    pointer-events: auto;
    background: linear-gradient(-90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 6s ease infinite;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
/* .loaderWhite{*/
/*    background-color: #fff;*/
/*}*/

.loaderFirst{
    display: none;
}
.wrapper{
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
}

.loaderLine{
    height: 4px;
    width: 130px;
    --c: no-repeat linear-gradient(rgba(255,255,255,0.5) 0 0, rgba(255,255,255,1) 100%);
    background: var(--c), var(--c), rgba(255, 255, 255, 0.5);
    background-size: 60% 100%;
    background-size: 60% 100%;
    animation: l16 3s infinite;
}
@keyframes l16 {
    0%   {
        background-position:-150% 0,-150% 0
    }
    66%  {
                background-position: 250% 0,-150% 0
            }
    100% {
        background-position: 250% 0, 250% 0
    }
}
@keyframes alls {
    0%{    left: 0;}
    70%{    left: 0;}
    100%{    left: -130%;}
}

.svgWraps{
    animation-delay: 1.5s;
    padding-right: 0;
    height: 74px;
    animation:drawSVG 1s linear ;
    animation-fill-mode:forwards
}

@keyframes drawSVG {
    100%{   padding-right: 15px;}
}

.svgWraps  {
    width: 0;
    overflow: hidden;
    animation:draw 1s linear ;
    animation-fill-mode:forwards;
}

@keyframes draw {
    100%{width: 150px}
}

.svgWrap{
    display: flex;
    align-items: flex-end;

}
.loaderSvg{
    width: 0;
    overflow: hidden;
    animation:drawLoad 0.5s linear ;
    animation-fill-mode:forwards;
    animation-delay: 2s;
}

.loaderWhite .loaderSvg svg path{
 fill: #FF2C3C;
}

.loaderWhite .textOrder svg path{
 fill: #000;
}

@keyframes drawLoad {
    100%{  width: 142px;}
}


.textWrapper{
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translateX(-50%);
    overflow: hidden;
    display: block;
}

.slideUPS{
    max-width: 140px;
    max-height: 50px;
    animation: slideUp 1.5s forwards;
    display: inline-block;
    transform: translateY(150%);
}

@keyframes slideUp {
    50% {
        transform: translateY(150%);
    }
    100% {
        transform: translateY(0%);
    }
}

.wrapperText{
    overflow: hidden;
    display: block;
    text-align: center;
}


.textOrder{
    /*animation: slideUpOrder 1.5s forwards;*/
    display: inline-block;
    /*transform: translateY(150%);*/
    /*animation-delay: 2s;*/
}


@keyframes slideUpOrder {
    50% {
        transform: translateY(150%);
    }
    100% {
        transform: translateY(0%);
    }
}


.slider{

}

.wrapperImg {
    display: flex!important;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 320px;
    max-width: 360px;
    margin: 20px 4px;
    box-sizing: border-box;
}

.wrapperImg img {
    width: calc(100% - 4px);
    object-fit: cover;
    border-radius: 20px;
}


@media (max-width: 360px) {
    .wrapperImg {
        max-width: none;
    }
}
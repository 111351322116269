.wrapper{
    background-color: var(--color-back-secondary);
}

.slider{
    background-color:  var(--color-back-primary);
    width: calc(100% - 12px);
    padding-left: 12px;
}



.inputWrapper{
    position: relative;
    display: inline-block;
    width: calc(100% - 40px);
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 56px;
    background-color: #fff;
}

.input{
    height: 44px;
    padding-left: 35px;
    width: calc(98% - 45px);
    /*font-family: Manrope;*/
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    background: #F3F2F8;
    border:none;
    border-radius: 100px

}

.svgWrapper{
    position: absolute;
    bottom: 8px;
    left: 32px;
}


.tabs{
    padding: 16px;
}

.borderBottem{
    border-radius: 0 0 20px 20px;
    margin-bottom: 8px;
    background-color: var(--color-back-primary);
}
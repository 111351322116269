

.wrapperActiveButtons{
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  max-width: max-content;
  margin: 0 auto;
  border-radius: 1000px;
  background-color: var(--color-gray-fill);
}

.but{
  width: 176px;
  /*height: 52px;*/
  padding: 12px 14px 12px 14px;
  border-radius: 100px;
  display: flex;

  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.but div{
  display: inline-flex;
}

.eat{
  color: var(--color-orange-accent);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.eat svg{
  padding-right: 8px;
}

.eat svg path{
  fill: var(--color-orange-accent);
}

.eat{
  color:  var(--color-orange-accent);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background-color:var(--color-gray-fill);
  transition: background-color 0.3s, color 0.3s;
}


.eat svg path{
  fill: var(--color-orange-accent);
}

.activeEat{
  background-color: var(--color-orange-accent);
  color: var(--color-text-in-dark);

}

.activeEat svg path{
  fill: var(--color-icons-white);
}


.item {
    width: 185px;
    padding: 10px 4px;
    box-sizing: border-box;
    position: relative;
}

.wrap{
    position: relative;
}

.icon{
    position: absolute;
    width: 26px;
    height: 26px;
    display: flex;
    border-radius: 55px;
    align-items: center;
    justify-content: center;
    right: 8px;
    top: 8px;
    z-index: 1;
    background-color: #fff;
    cursor: pointer;
    pointer-events: auto;
}




.similiar{
    width: 100%;
    margin: 0;
}

@media (max-width: 450px)  {
  .item{
      width: calc(50%);
      padding: 10px 4px;
  }

    .sliderItem{
        width: calc(100% - 8px);
        margin: 10px 4px;
        max-width: 177px;
    }
    .similiar{
        width: 100%;
margin: 0;
    }


    .icon{
        top: 18px;
    }
    .isSliderIcon{
        right: 11px;
    }
}

 .smallItem{
    width: 125px;
}


@media (max-width: 450px)  {
    .smallItem{
        width: calc(33%);

    }
}

.item .name {
    font-size: 14px;
    color: var(--color-text-in-light);
    position: relative;
    z-index: 2;
   margin: 8px 0 4px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item .gram {
    font-size: 12px;
    color: var(--color-text-secondary);
    position: relative;
    z-index: 2;
   margin: 0px 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.smallItem .name{
  font-size: 12px;
    color: #fff;
}

.item .price {
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    max-width: max-content;
    color: var(--color-orange-accent);
    position: relative;
    box-sizing: border-box;
    padding: 4px 8px;
    z-index: 2;
    background-color: var(--color-gray-fill);
}

.smallItem .price{
    background-color: #F3F2F8;
}


.noFoto{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-gray-fill);
    color: var(--color-text-secondary);
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03px;
}

.menuButtonCategory {
    border-radius: 12px;
    width: 177px;
    height: 177px;
    position: relative;
    overflow: hidden;
    /*background-color: ;*/
}

.wrapIcon{
    position: absolute;
    left: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background-color: var(--color-gray-fill);
}

.fast{
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 55px;
    background-color: var(--color-gray-fill);
}

.fast svg{
    width: 20px;
    height: 20px;
}

.fastActive{
    background-color: var(--color-success);
    box-sizing: border-box;
    padding: 8px 7px 8px 9px;
}

.notFastActive svg path{
    stroke: var(--color-orange-accent);
}

.menuButtonCategory svg {

}

@media (max-width: 450px)  {
    .menuButtonCategory{
        width: 100%;
    }
    .isSlider{
        width: 177px;
        margin: 10px 4px;
    }
}


 .smallItem .menuButtonCategory{
    width: 115px;
    height: 115px;
}
@media (max-width: 450px)  {
    .smallItem .menuButtonCategory{
        width: 100%;
        height: 115px;
    }
}

.menuButtonCategory img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.discount{
    display: flex;
    align-items: center;
    color: var(--color-orange-accent);
}

.priceDisc{
    color: var(--color-text-secondary);
    font-size: 14px;
    font-weight: 600;
    position: relative;
}

.discPersent{
    color: var(--color-orange-accent);
    font-size: 14px;

    font-weight: 600;
    position: relative;
}

.disc{
    margin-left: 4px;
}

.priceDisc:before{
    content: "";
    border-bottom: 2px solid var(--color-icons-black);
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
}

.smallItem .priceDisc:before{

    border-bottom: 2px solid #1F1D2B;

}


@import 'styles/globals.css';
@import 'styles/themes/white.css';
@import 'styles/themes/black.css';

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  background-color: var(--color-black-body);
  /*overflow: hidden;*/
  /*background-color: #2E2F34;*/
}

#root {
  /*position: fixed;*/
  /*top: 0;*/
  /*left: 0;*/
  /*width: 100vw;*/
  /*height: 100vh;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

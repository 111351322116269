.wrapper {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 32px);
    background-color: var(--color-back-primary);
    position: relative;
    justify-content: flex-start;
    padding: 0 16px;
    border-radius: 20px;

    margin-bottom: 8px;
}

@media (max-width: 450px)  {
    .wrapper{
        width: calc(100% - 16px);
        margin: 10px 0;
        padding: 0 8px;
    }
}

.title {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text-in-light);
    padding-top: 20px;
    padding-bottom: 16px;
    width: 100%;
}

.item {
    width: 177px;
    margin: 10px 3.5px;
}



.item name {
    font-size: 14px;
    color: #1F1D2B;

    position: relative;
    z-index: 2;
}

.item price {
    font-size: 15px;
    font-weight: 800;
    color: #F85D42;

    position: relative;
    z-index: 2;
}

.menuButtonCategory {
    border-radius: 12px;
    width: 177px;
    height: 177px;
    position: relative;
    overflow: hidden;
    background-color: #F3F2F8;
}


.menuButtonCategory img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



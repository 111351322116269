.wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.link{
    text-decoration: none;
}

.text{
    color: var(--color-text-in-light);
    font-weight: 600;
    font-size: 13px;
    margin-left: 8px;
    text-decoration: none;
}

.main {
    min-width: 46px;
    max-width: 46px;
    text-decoration: none;
    height: 46px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 16px;
    border-radius: 16px;
}

.main svg {
    width: 22px;
    height: 22px;
}

.wrapMain{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    text-decoration: none;
    background-color: #fff;
    border-radius: 100px;
    padding: 12px 21px;
    margin: 8px 16px;
}

.sucess{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.sucessTitle{
    font-weight: 700;
    font-size: 24px;
    color: var(--color-text-in-light);
}

.animation{
    margin-top: -17px;
}

.feedback {
    margin-top: 20px;
    text-align: center;
    position: fixed;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    bottom: 0;
    width: 100%;
}

.feedbackTitle {
    font-size: 20px;
   box-sizing: border-box;
    padding: 16px 0;
    font-weight: 700;

}

.stars {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.star {
    font-size: 50px;
    line-height: 40px;
    cursor: pointer;
    color: #ccc;
}

.star.selected {
    color: #f5a623;
}
.wrapComment{
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
}
.comment {
    width: 100%;
    height: 80px;
    margin: 10px auto;
    padding: 7px 12px;
    box-sizing: border-box;
    background-color: var(--color-gray-fill);
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-text-in-light);
    font-weight: 600;
}

.comment::placeholder{
    color: var(--color-icons-dark);

}

.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 10px 0px;
    box-sizing: border-box;
    padding: 0 16px;
}

.contactDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 16px;
}

.input {
    width: 100%;
    padding: 10px;
    background-color: var(--color-gray-fill);
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.submit {
    background-color: var(--color-orange-accent);
    width: 100%;
    padding: 14px 18px;
    /*max-width: 280px;*/
    box-sizing: border-box;
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    font-weight: 600;
    border-radius: 55px;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
    border: 0;
}

.wrapperBut{
    box-sizing: border-box;
    padding: 0 16px;
}
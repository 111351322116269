.slide{
    /*background: linear-gradient(349.6deg, #FF5B55 5.39%, #FFC74F 100%);*/
    width: 100%;
    height: 100vh;
    position: absolute;
    box-sizing: border-box;
    pointer-events: auto;
    left: 0;
    top: 0;
}

.slide-0{
    background: linear-gradient(349.6deg, #FF5B55 5.39%, #FFC74F 100%);
}
.slide-1{
    background: linear-gradient(171.97deg, #AF91FF 0%, #ECA0C9 46.92%, #FFC966 93.84%);

}
.slide-2{
    background: linear-gradient(347.55deg, #84D942 3.89%, #D7E64E 54.39%, #FFD15A 100%);

}
.slide-3{
    background: linear-gradient(167.53deg, #F94768 0%, #996DD2 50.35%, #28B5ED 96.13%);

}
.slide-4{
    background: linear-gradient(192.37deg, #FFF6B7 0%, #F6416C 96.04%);

}


.wrap{
    margin-top: 86px;
    position: relative;
}
.item{
    width: 300px;
    position: relative;
    margin: 20px auto 0;
    max-height: 420px;
    overflow: hidden;
    border-radius: 45px 45px 0 0;
}

.icon{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 330px;
    top: -10px;
    pointer-events: none;
    z-index: 1;
    opacity: 1;
}


.line{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 4px;
}

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px; /* Или любой другой отступ */
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background-color: #fff; /* Цвет неактивных точек */
    margin: 0 4px;
    cursor: pointer;
    padding-inline: 3px;
    opacity: 0.5;
    border: none; /* Убираем стандартные рамки */
    transition: opacity 0.3s;
}

.active {
    opacity: 1;/* Цвет активной точки */
    width: 8px;
    height: 8px;
    padding-inline: 4px;
}

.text{
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 31px;
}

.showMoreButton{
    position: absolute;
    right: 16px;
    top: 12px;
    border: none;
    width: 126px;
    height: 38px;
    background-color: var(--color-gray-fill);
    color: var(--color-text-in-light);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    z-index: 3;
    text-decoration: none;
}

.showMoreButton svg{
    margin-left: 4px;
    width: 12px;
    height: 12px;

}
.showMoreButton svg path{
    stroke: var(--color-icons-black);
}

.showMoreButtonNext{
    position: absolute;
    left: 16px;
    top: 12px;
    border: none;
    width: 126px;
    height: 38px;
    background-color: var(--color-gray-fill);
    color: var(--color-text-in-light);
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    z-index: 3;
    text-decoration: none;
}

.showMoreButton svg{
    margin-left: 4px;
    width: 12px;
    height: 12px;

}
.showMoreButton svg path{
    stroke: var(--color-icons-black);
}